<template>
	<div class="flex flex-col pt-6 h-full">
		<UProgress animation="swing" class="mb-6"/>
		<div v-for="i in 20" class="w-full pt-1 flex flex-row justify-between my-2.5 gap-2">
			<USkeleton class="w-1/12 h-7" />
			<USkeleton class="w-2/12 h-7" />
			<USkeleton class="w-1/12 h-7" />
			<USkeleton class="w-3/12 h-7" />
			<USkeleton class="w-1/12 h-7" />
			<USkeleton class="w-1/12 h-7" />
			<USkeleton class="w-2/12 h-7" />
			<USkeleton class="w-1/12 h-7" />
		</div>
	</div>
</template>

<script setup lang="ts">

</script>